import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICountryPublicHolidayRule } from '../../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRule';
import { ICountryPublicHolidayRuleUpsert } from '../../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRuleUpsert';

export default class CountryPublicHolidayRuleHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList() : AxiosPromise<Array<ICountryPublicHolidayRule>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/CountryPublicHolidayRule/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : ICountryPublicHolidayRuleUpsert, type : 'Add' | 'Edit') : AxiosPromise<ICountryPublicHolidayRule> => {
        return axios.post(`${API_URL}/v1/MasterData/CountryPublicHolidayRule/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<ICountryPublicHolidayRule> => {
        return axios.delete(`${API_URL}/v1/MasterData/CountryPublicHolidayRule/Delete`, {
            params: { id },
        });
    }
}