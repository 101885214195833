import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ISubdivision } from '../../../@types/model/masterData/subdivision/subdivision';
import { ISubdivisionUpsert } from '../../../@types/model/masterData/subdivision/subdivisionUpsert';

export default class SubdivisionHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(divisionIds : Array<number> | null) : AxiosPromise<Array<ISubdivision>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Subdivision/GetList`, {
            params: { divisionIds },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : ISubdivisionUpsert, type : 'Add' | 'Edit') : AxiosPromise<ISubdivision> => {
        return axios.post(`${API_URL}/v1/MasterData/Subdivision/${type}`, upsert);
    }

    public static delete = (subdivisionId : number) : AxiosPromise<ISubdivision> => {
        return axios.delete(`${API_URL}/v1/MasterData/Subdivision/Delete`, {
            params: { subdivisionId },
        });
    }
}