import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IEmployeeCard } from '../../../@types/model/masterData/employeeCard/employeeCard';
import { IEmployeeCardUpsert } from '../../../@types/model/masterData/employeeCard/employeeCardUpsert';

export default class EmployeeCardHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(divisionIds : Array<number>) : AxiosPromise<Array<IEmployeeCard>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/EmployeeCard/GetList`, {
            params: {
                divisionIds
            },
            paramsSerializer: {
                indexes: null,
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IEmployeeCardUpsert, type : 'Add' | 'Edit') : AxiosPromise<IEmployeeCard> => {
        return axios.post(`${API_URL}/v1/MasterData/EmployeeCard/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<IEmployeeCard> => {
        return axios.delete(`${API_URL}/v1/MasterData/EmployeeCard/Delete`, {
            params: { id },
        });
    }
}