import { Transaction, UpgradeDB, openDb } from 'idb';
import { upgradeDb } from './localStorageService';
import { IMasterDataSync } from '../@types/model/sync/masterDataSync';
import { IActivity } from '../@types/model/masterData/activity/activity';
import { IBlock } from '../@types/model/masterData/block/block';
import { IClockingMethod } from '../@types/model/masterData/clockingMethod/clockingMethod';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { IContactUs } from '../@types/model/masterData/contactUs/contactUs';
import { ICountry } from '../@types/model/masterData/country/country';
import { ICrop } from '../@types/model/masterData/crop/crop';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IDivision } from '../@types/model/masterData/division/division';
import { IEmployee } from '../@types/model/masterData/employee/employee';
import { IEmployeeRate } from '../@types/model/masterData/employeeRate/employeeRate';
import { IField } from '../@types/model/masterData/field/field';
import { IJobType } from '../@types/model/masterData/jobType/jobType';
import { IMinimumWageHistory } from '../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import { INews } from '../@types/model/masterData/news/news';
import { IProductionUnit } from '../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../@types/model/masterData/project/project';
import { IPublicHoliday } from '../@types/model/masterData/publicHoliday/publicHoliday';
import { IRootStock } from '../@types/model/masterData/rootStock/rootStock';
import { IScanType } from '../@types/model/masterData/scanType/scanType';
import { ISubdivision } from '../@types/model/masterData/subdivision/subdivision';
import { ITariffCalculation } from '../@types/model/masterData/tariffCalculation/tariffCalculation';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { IClockingSystem } from '../@types/model/masterData/clockingSystem/clockingSystem';
import { IDivisionDayOfWeek } from '../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import { INorm } from '../@types/model/masterData/norm/norm';
import { IActivityType } from '../@types/model/masterData/activityType/activityType';
import { IEmployeeSetup } from '../@types/model/masterData/employeeSetup/employeeSetup';
import { ICountryPublicHolidayRule } from '../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRule';
import { IPayRun } from '../@types/model/masterData/payRun/payRun';
import { IActivityRate } from '../@types/model/masterData/activityRate/activityRate';
import { ICompanyReference } from '../@types/model/masterData/companyReference/companyReference';
import { IBusinessRule } from '../@types/model/masterData/businessRule/businessRule';
import { IGlCode } from '../@types/model/masterData/glCode/glCode';
import { ITariffType } from '../@types/model/masterData/tariffType/tariffType';
import { ICapital } from '../@types/model/masterData/capital/capital';
import { IDevice } from '../@types/model/masterData/device/device';
import { IDepartmentGroup } from '../@types/model/masterData/departmentGroup/departmentGroup';
// import { ICropVariety } from '../@types/model/masterData/cropVariety/cropVariety';
import { IEmployeeCard } from '../@types/model/masterData/employeeCard/employeeCard';

// IndexDB Master Data object store names
export const ACTIVITY_TABLE_NAME = 'activity-table';
export const ACTIVITY_TYPE_TABLE_NAME = 'activity-type-table';
export const BLOCK_TABLE_NAME = 'block-table';
export const CLOCKING_METHOD_TABLE_NAME = 'clocking-method-table';
export const CLOCKING_SYSTEM_TABLE_NAME = 'clocking-system-table';
export const EMPLOYEE_SETUP_TABLE_NAME = 'employee-setup-table';
export const COMMODITY_TABLE_NAME = 'commodity-table';
export const CONTACT_US_TABLE_NAME = 'contact-us-table';
export const COUNTRY_TABLE_NAME = 'country-table';
export const COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME = 'country-public-holiday-rule-table';
export const CROP_TABLE_NAME = 'crop-table';
export const DEPARTMENT_TABLE_NAME = 'department-table';
export const DIVISION_TABLE_NAME = 'division-table';
export const DIVISION_DAYS_OF_WEEK_TABLE_NAME = 'division-days-of-week-table';
export const EMPLOYEE_TABLE_NAME = 'employee-table';
export const EMPLOYEE_RATE_TABLE_NAME = 'employee-rate-table';
export const FIELD_TABLE_NAME = 'field-table';
export const JOB_TYPE_TABLE_NAME = 'job-type-table';
export const MINIMUM_WAGE_HISTORY_TABLE_NAME = 'minimum-wage-history-table';
export const NEWS_TABLE_NAME = 'news-table';
export const PRODUCTION_UNIT_TABLE_NAME = 'production-unit-table';
export const PROJECT_TABLE_NAME = 'project-table';
export const PUBLIC_HOLIDAY_TABLE_NAME = 'public-holiday-table';
export const ROOT_STOCK_TABLE_NAME = 'root-stock-table';
export const SCAN_TYPE_TABLE_NAME = 'scan-type-table';
export const SUBDIVISION_TABLE_NAME = 'subdivision-table';
export const TARIFF_CALCULATION_TABLE_NAME = 'tarif-calculation-table';
export const TARIFF_TYPE_TABLE_NAME = 'tarif-type-table';
export const VARIETY_TABLE_NAME = 'variety-table';
export const NORM_TABLE_NAME = 'norm-table';
export const PAY_RUN_TABLE_NAME = 'pay-run-table';
export const ACTIVITY_RATE_TABLE_NAME = 'activity-rate-table';
export const COMPANY_REFERENCE_TABLE_NAME = 'company-reference-table';
export const BUSINESS_RULE_TABLE_NAME = 'business-rule-table';
export const GL_CODE_TABLE_NAME = 'gl-code-table';
export const CAPITAL_TABLE_NAME = 'capital-table';
export const DEVICE_TABLE_NAME = 'device-table';
export const DEPARTMENT_GROUP_TABLE_NAME = 'department-group-table';
export const CROP_VARIETY_TABLE_NAME = 'crop-variety-table';
export const EMPLOYEE_CARD_TABLE_NAME = 'employee-card-table';

export const masterDataIndexedDBStores = (db : UpgradeDB) : void => {
    
    if (!db.objectStoreNames.contains(ACTIVITY_TABLE_NAME)) {
        db.createObjectStore<IActivity, string>(ACTIVITY_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(ACTIVITY_TYPE_TABLE_NAME)) {
        db.createObjectStore<IActivityType, string>(ACTIVITY_TYPE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(BLOCK_TABLE_NAME)) {
        db.createObjectStore<IBlock, string>(BLOCK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CLOCKING_METHOD_TABLE_NAME)) {
        db.createObjectStore<IClockingMethod, string>(CLOCKING_METHOD_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CLOCKING_SYSTEM_TABLE_NAME)) {
        db.createObjectStore<IClockingSystem, string>(CLOCKING_SYSTEM_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(EMPLOYEE_SETUP_TABLE_NAME)) {
        db.createObjectStore<IEmployeeSetup, string>(EMPLOYEE_SETUP_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COMMODITY_TABLE_NAME)) {
        db.createObjectStore<ICommodity, string>(COMMODITY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CONTACT_US_TABLE_NAME)) {
        db.createObjectStore<IContactUs, string>(CONTACT_US_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COUNTRY_TABLE_NAME)) {
        db.createObjectStore<ICountry, string>(COUNTRY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME)) {
        db.createObjectStore<ICountry, string>(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CROP_TABLE_NAME)) {
        db.createObjectStore<ICrop, string>(CROP_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DEPARTMENT_TABLE_NAME)) {
        db.createObjectStore<IDepartment, string>(DEPARTMENT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DIVISION_TABLE_NAME)) {
        db.createObjectStore<IDivision, string>(DIVISION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DIVISION_DAYS_OF_WEEK_TABLE_NAME)) {
        db.createObjectStore<IDivisionDayOfWeek, string>(DIVISION_DAYS_OF_WEEK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(EMPLOYEE_TABLE_NAME)) {
        db.createObjectStore<IEmployee, string>(EMPLOYEE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(EMPLOYEE_RATE_TABLE_NAME)) {
        db.createObjectStore<IEmployeeRate, string>(EMPLOYEE_RATE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(FIELD_TABLE_NAME)) {
        db.createObjectStore<IField, string>(FIELD_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(JOB_TYPE_TABLE_NAME)) {
        db.createObjectStore<IJobType, string>(JOB_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(MINIMUM_WAGE_HISTORY_TABLE_NAME)) {
        db.createObjectStore<IMinimumWageHistory, string>(MINIMUM_WAGE_HISTORY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(NEWS_TABLE_NAME)) {
        db.createObjectStore<INews, string>(NEWS_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PRODUCTION_UNIT_TABLE_NAME)) {
        db.createObjectStore<IProductionUnit, string>(PRODUCTION_UNIT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PROJECT_TABLE_NAME)) {
        db.createObjectStore<IProject, string>(PROJECT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PUBLIC_HOLIDAY_TABLE_NAME)) {
        db.createObjectStore<IPublicHoliday, string>(PUBLIC_HOLIDAY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(ROOT_STOCK_TABLE_NAME)) {
        db.createObjectStore<IRootStock, string>(ROOT_STOCK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(SCAN_TYPE_TABLE_NAME)) {
        db.createObjectStore<IScanType, string>(SCAN_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(SUBDIVISION_TABLE_NAME)) {
        db.createObjectStore<ISubdivision, string>(SUBDIVISION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(TARIFF_CALCULATION_TABLE_NAME)) {
        db.createObjectStore<ITariffCalculation, string>(TARIFF_CALCULATION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(TARIFF_TYPE_TABLE_NAME)) {
        db.createObjectStore<ITariffType, string>(TARIFF_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(VARIETY_TABLE_NAME)) {
        db.createObjectStore<IVariety, string>(VARIETY_TABLE_NAME);
    }    

    if (!db.objectStoreNames.contains(NORM_TABLE_NAME)) {
        db.createObjectStore<INorm, string>(NORM_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(PAY_RUN_TABLE_NAME)) {
        db.createObjectStore<IPayRun, string>(PAY_RUN_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(ACTIVITY_RATE_TABLE_NAME)) {
        db.createObjectStore<IActivityRate, string>(ACTIVITY_RATE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(COMPANY_REFERENCE_TABLE_NAME)) {
        db.createObjectStore<ICompanyReference, string>(COMPANY_REFERENCE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(BUSINESS_RULE_TABLE_NAME)) {
        db.createObjectStore<IBusinessRule, string>(BUSINESS_RULE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(GL_CODE_TABLE_NAME)) {
        db.createObjectStore<IGlCode, string>(GL_CODE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(CAPITAL_TABLE_NAME)) {
        db.createObjectStore<ICapital, string>(CAPITAL_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(DEVICE_TABLE_NAME)) {
        db.createObjectStore<IDevice, string>(DEVICE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(DEPARTMENT_GROUP_TABLE_NAME)) {
        db.createObjectStore<IDepartmentGroup, string>(DEPARTMENT_GROUP_TABLE_NAME);
    }

    // if (!db.objectStoreNames.contains(CROP_VARIETY_TABLE_NAME)) {
    //     db.createObjectStore<ICropVariety, string>(CROP_VARIETY_TABLE_NAME);
    // }

    if (!db.objectStoreNames.contains(EMPLOYEE_CARD_TABLE_NAME)) {
        db.createObjectStore<IEmployeeCard, string>(EMPLOYEE_CARD_TABLE_NAME);
    }
    
    // Stores to remove
    if (db.objectStoreNames.contains('pay-point-table')) {
        db.deleteObjectStore('pay-point-table');
    }
    
    if (db.objectStoreNames.contains('clocking-system-employee-setup-table')) {
        db.deleteObjectStore('clocking-system-employee-setup-table');
    }
    
    if (db.objectStoreNames.contains('employee-rate-history-table')) {
        db.deleteObjectStore('employee-rate-history-table');
    }
    
    if (db.objectStoreNames.contains(CROP_VARIETY_TABLE_NAME)) {
        db.deleteObjectStore(CROP_VARIETY_TABLE_NAME);
    }
};


export const clearIndexedDBObjectStores = async () : Promise<void> => {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx : Transaction = db.transaction([
        ACTIVITY_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        BLOCK_TABLE_NAME,
        CLOCKING_METHOD_TABLE_NAME,
        CLOCKING_SYSTEM_TABLE_NAME,
        EMPLOYEE_SETUP_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        CONTACT_US_TABLE_NAME,
        COUNTRY_TABLE_NAME,
        COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME,
        CROP_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        DIVISION_TABLE_NAME,
        DIVISION_DAYS_OF_WEEK_TABLE_NAME,
        EMPLOYEE_TABLE_NAME,
        EMPLOYEE_RATE_TABLE_NAME,
        FIELD_TABLE_NAME,
        JOB_TYPE_TABLE_NAME,
        MINIMUM_WAGE_HISTORY_TABLE_NAME,
        NEWS_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PUBLIC_HOLIDAY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        SCAN_TYPE_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        TARIFF_CALCULATION_TABLE_NAME,
        TARIFF_TYPE_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NORM_TABLE_NAME,
        PAY_RUN_TABLE_NAME,
        ACTIVITY_RATE_TABLE_NAME,
        COMPANY_REFERENCE_TABLE_NAME,
        BUSINESS_RULE_TABLE_NAME,
        GL_CODE_TABLE_NAME,
        CAPITAL_TABLE_NAME,
        DEVICE_TABLE_NAME,
        DEPARTMENT_GROUP_TABLE_NAME,
        // CROP_VARIETY_TABLE_NAME,
        EMPLOYEE_CARD_TABLE_NAME,
    ], 'readwrite');

    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const clockingMethodStore = tx.objectStore(CLOCKING_METHOD_TABLE_NAME);
    const clockingSystemStore = tx.objectStore(CLOCKING_SYSTEM_TABLE_NAME);
    const employeeSetupStore = tx.objectStore(EMPLOYEE_SETUP_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const contactUsStore = tx.objectStore(CONTACT_US_TABLE_NAME);
    const countryStore = tx.objectStore(COUNTRY_TABLE_NAME);
    const countryPublicHolidayRuleStore = tx.objectStore(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME);
    const cropStore = tx.objectStore(CROP_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const divisionDaysOfWeekStore = tx.objectStore(DIVISION_DAYS_OF_WEEK_TABLE_NAME);
    const employeeStore = tx.objectStore(EMPLOYEE_TABLE_NAME);
    const EmployeeRateStore = tx.objectStore(EMPLOYEE_RATE_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const jobTypeStore = tx.objectStore(JOB_TYPE_TABLE_NAME);
    const minimumWageHistoryStore = tx.objectStore(MINIMUM_WAGE_HISTORY_TABLE_NAME);
    const newsStore = tx.objectStore(NEWS_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const publicHolidayStore = tx.objectStore(PUBLIC_HOLIDAY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const scanTypeStore = tx.objectStore(SCAN_TYPE_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const tariffCalculationStore = tx.objectStore(TARIFF_CALCULATION_TABLE_NAME);
    const tariffTypeStore = tx.objectStore(TARIFF_TYPE_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const normStore = tx.objectStore(NORM_TABLE_NAME);
    const payRunStore = tx.objectStore(PAY_RUN_TABLE_NAME);
    const activityRateStore = tx.objectStore(ACTIVITY_RATE_TABLE_NAME);
    const companyReferenceStore = tx.objectStore(COMPANY_REFERENCE_TABLE_NAME);
    const businessRuleStore = tx.objectStore(BUSINESS_RULE_TABLE_NAME);
    const glCodesStore = tx.objectStore(GL_CODE_TABLE_NAME);
    const capitalStore = tx.objectStore(CAPITAL_TABLE_NAME);
    const deviceStore = tx.objectStore(DEVICE_TABLE_NAME);
    const departmentGroupStore = tx.objectStore(DEPARTMENT_GROUP_TABLE_NAME);
    // const cropVarietyStore = tx.objectStore(CROP_VARIETY_TABLE_NAME);
    const employeeCardStore = tx.objectStore(EMPLOYEE_CARD_TABLE_NAME);

    await Promise.all([
        activityStore.clear(),
        activityTypeStore.clear(),
        blockStore.clear(),
        clockingMethodStore.clear(),
        clockingSystemStore.clear(),
        employeeSetupStore.clear(),
        commodityStore.clear(),
        contactUsStore.clear(),
        countryStore.clear(),
        countryPublicHolidayRuleStore.clear(),
        cropStore.clear(),
        departmentStore.clear(),
        divisionStore.clear(),
        divisionDaysOfWeekStore.clear(),
        employeeStore.clear(),
        EmployeeRateStore.clear(),
        fieldStore.clear(),
        jobTypeStore.clear(),
        minimumWageHistoryStore.clear(),
        newsStore.clear(),
        productionUnitStore.clear(),
        projectStore.clear(),
        publicHolidayStore.clear(),
        rootStockStore.clear(),
        scanTypeStore.clear(),
        subdivisionStore.clear(),
        tariffCalculationStore.clear(),
        tariffTypeStore.clear(),
        varietyStore.clear(),
        normStore.clear(),
        payRunStore.clear(),
        activityRateStore.clear(),
        companyReferenceStore.clear(),
        businessRuleStore.clear(),
        glCodesStore.clear(),
        capitalStore.clear(),
        deviceStore.clear(),
        departmentGroupStore.clear(),
        // cropVarietyStore.clear(),
        employeeCardStore.clear(),
    ]);
    await tx.complete;
};

export const setIndexedDBMasterData = async (masterData : IMasterDataSync) : Promise<IMasterDataSync> => {    
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx : Transaction = db.transaction([
        ACTIVITY_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        BLOCK_TABLE_NAME,
        CLOCKING_METHOD_TABLE_NAME,
        CLOCKING_SYSTEM_TABLE_NAME,
        EMPLOYEE_SETUP_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        CONTACT_US_TABLE_NAME,
        COUNTRY_TABLE_NAME,
        COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME,
        CROP_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        DIVISION_TABLE_NAME,
        DIVISION_DAYS_OF_WEEK_TABLE_NAME,
        EMPLOYEE_TABLE_NAME,
        EMPLOYEE_RATE_TABLE_NAME,
        FIELD_TABLE_NAME,
        JOB_TYPE_TABLE_NAME,
        MINIMUM_WAGE_HISTORY_TABLE_NAME,
        NEWS_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PUBLIC_HOLIDAY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        SCAN_TYPE_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        TARIFF_CALCULATION_TABLE_NAME,
        TARIFF_TYPE_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NORM_TABLE_NAME,
        PAY_RUN_TABLE_NAME,
        ACTIVITY_RATE_TABLE_NAME,
        COMPANY_REFERENCE_TABLE_NAME,
        BUSINESS_RULE_TABLE_NAME,
        GL_CODE_TABLE_NAME,
        CAPITAL_TABLE_NAME,
        DEVICE_TABLE_NAME,
        DEPARTMENT_GROUP_TABLE_NAME,
        // CROP_VARIETY_TABLE_NAME,
        EMPLOYEE_CARD_TABLE_NAME
    ], 'readwrite');

    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const clockingMethodStore = tx.objectStore(CLOCKING_METHOD_TABLE_NAME);
    const clockingSystemsStore = tx.objectStore(CLOCKING_SYSTEM_TABLE_NAME);
    const employeeSetupStore = tx.objectStore(EMPLOYEE_SETUP_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const contactUsStore = tx.objectStore(CONTACT_US_TABLE_NAME);
    const countryStore = tx.objectStore(COUNTRY_TABLE_NAME);
    const countryPublicHolidayRuleStore = tx.objectStore(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME);
    const cropStore = tx.objectStore(CROP_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const divisionDaysOfWeekStore = tx.objectStore(DIVISION_DAYS_OF_WEEK_TABLE_NAME);
    const employeeStore = tx.objectStore(EMPLOYEE_TABLE_NAME);
    const EmployeeRateStore = tx.objectStore(EMPLOYEE_RATE_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const jobTypeStore = tx.objectStore(JOB_TYPE_TABLE_NAME);
    const minimumWageHistoryStore = tx.objectStore(MINIMUM_WAGE_HISTORY_TABLE_NAME);
    const newsStore = tx.objectStore(NEWS_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const publicHolidayStore = tx.objectStore(PUBLIC_HOLIDAY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const scanTypeStore = tx.objectStore(SCAN_TYPE_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const tariffCalculationStore = tx.objectStore(TARIFF_CALCULATION_TABLE_NAME);
    const tariffTypeStore = tx.objectStore(TARIFF_TYPE_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const normStore = tx.objectStore(NORM_TABLE_NAME);
    const payRunStore = tx.objectStore(PAY_RUN_TABLE_NAME);
    const activityRateStore = tx.objectStore(ACTIVITY_RATE_TABLE_NAME);
    const companyReferenceStore = tx.objectStore(COMPANY_REFERENCE_TABLE_NAME);
    const businessRuleStore = tx.objectStore(BUSINESS_RULE_TABLE_NAME);
    const glCodeStore = tx.objectStore(GL_CODE_TABLE_NAME);
    const capitalStore = tx.objectStore(CAPITAL_TABLE_NAME);
    const deviceStore = tx.objectStore(DEVICE_TABLE_NAME);
    const departmentGroupStore = tx.objectStore(DEPARTMENT_GROUP_TABLE_NAME);
    // const cropVarietyStore = tx.objectStore(CROP_VARIETY_TABLE_NAME);
    const employeeCardStore = tx.objectStore(EMPLOYEE_CARD_TABLE_NAME);

    // Update functions for each store
    masterData.activities.forEach((x) => {
        activityStore.put(x, x.guid);
    });

    masterData.activityTypes.forEach((x) => {
        activityTypeStore.put(x, x.guid);
    });

    masterData.blocks.forEach((x) => {
        blockStore.put(x, x.guid);
    });

    masterData.clockingMethods.forEach((x) => {
        clockingMethodStore.put(x, x.guid);
    });

    masterData.clockingSystems.forEach((x) => {
        clockingSystemsStore.put(x, x.guid);
    });

    masterData.employeeSetups.forEach((x) => {
        employeeSetupStore.put(x, x.guid);
    });

    masterData.commodities.forEach((x) => {
        commodityStore.put(x, x.guid);
    });

    masterData.contactUs.forEach((x) => {
        contactUsStore.put(x, x.guid);
    });

    masterData.countries.forEach((x) => {
        countryStore.put(x, x.guid);
    });

    masterData.countryPublicHolidayRules.forEach((x) => {
        countryPublicHolidayRuleStore.put(x, x.guid);
    });

    masterData.crops.forEach((x) => {
        cropStore.put(x, x.guid);
    });

    masterData.departments.forEach((x) => {
        departmentStore.put(x, x.guid);
    });

    masterData.divisions.forEach((x) => {
        divisionStore.put(x, x.guid);
    });

    masterData.divisionDaysOfWeek.forEach((x) => {
        divisionDaysOfWeekStore.put(x, x.guid);
    });

    masterData.employees.forEach((x) => {
        employeeStore.put(x, x.guid);
    });

    masterData.employeeRates.forEach((x) => {
        EmployeeRateStore.put(x, x.guid);
    });

    masterData.fields.forEach((x) => {
        fieldStore.put(x, x.guid);
    });

    masterData.jobTypes.forEach((x) => {
        jobTypeStore.put(x, x.guid);
    });

    masterData.minimumWageHistories.forEach((x) => {
        minimumWageHistoryStore.put(x, x.guid);
    });

    masterData.news.forEach((x) => {
        newsStore.put(x, x.guid);
    });

    masterData.productionUnits.forEach((x) => {
        productionUnitStore.put(x, x.guid);
    });

    masterData.projects.forEach((x) => {
        projectStore.put(x, x.guid);
    });

    masterData.publicHolidays.forEach((x) => {
        publicHolidayStore.put(x, x.guid);
    });

    masterData.rootStocks.forEach((x) => {
        rootStockStore.put(x, x.guid);
    });

    masterData.scanTypes.forEach((x) => {
        scanTypeStore.put(x, x.guid);
    });

    masterData.subdivisions.forEach((x) => {
        subdivisionStore.put(x, x.guid);
    });

    masterData.tariffCalculations.forEach((x) => {
        tariffCalculationStore.put(x, x.guid);
    });

    masterData.tariffTypes.forEach((x) => {
        tariffTypeStore.put(x, x.guid);
    });

    masterData.varieties.forEach((x) => {
        varietyStore.put(x, x.guid);
    });

    masterData.norms.forEach((x) => {
        normStore.put(x, x.guid);
    });

    masterData.payRuns.forEach((x) => {
        payRunStore.put(x, x.guid);
    });

    masterData.activityRates.forEach((x) => {
        activityRateStore.put(x, x.guid);
    });

    masterData.companyReferences.forEach((x) => {
        companyReferenceStore.put(x, x.guid);
    });

    masterData.businessRules.forEach((x) => {
        businessRuleStore.put(x, x.guid);
    });

    masterData.glCodes.forEach((x) => {
        glCodeStore.put(x, x.guid);
    });

    masterData.capitals.forEach((x) => {
        capitalStore.put(x, x.guid);
    });

    masterData.devices.forEach((x) => {
        deviceStore.put(x, x.guid);
    });

    masterData.departmentGroups.forEach((x) => {
        departmentGroupStore.put(x, x.guid);
    });

    // masterData.cropVarieties.forEach((x) => {
    //     cropVarietyStore.put(x, x.guid);
    // });

    masterData.employeeCards.forEach((x) => {
        employeeCardStore.put(x, x.guid);
    });

    const updatedActivities = await tx.objectStore<IActivity>(ACTIVITY_TABLE_NAME).getAll();
    const updatedActivityTypes = await tx.objectStore<IActivityType>(ACTIVITY_TYPE_TABLE_NAME).getAll();
    const updatedBlocks = await tx.objectStore<IBlock>(BLOCK_TABLE_NAME).getAll();
    const updatedClockingMethods = await tx.objectStore<IClockingMethod>(CLOCKING_METHOD_TABLE_NAME).getAll();
    const updatedClockingSystems = await tx.objectStore<IClockingSystem>(CLOCKING_SYSTEM_TABLE_NAME).getAll();
    const updatedEmployeeSetups = await tx.objectStore<IEmployeeSetup>(EMPLOYEE_SETUP_TABLE_NAME).getAll();
    const updatedCommodities = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();
    const updatedContactUs = await tx.objectStore<IContactUs>(CONTACT_US_TABLE_NAME).getAll();
    const updatedCountries = await tx.objectStore<ICountry>(COUNTRY_TABLE_NAME).getAll();
    const updatedCountryPublicHolidayRules = await tx.objectStore<ICountryPublicHolidayRule>(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME).getAll();
    const updatedCrops = await tx.objectStore<ICrop>(CROP_TABLE_NAME).getAll();
    const updatedDepartments = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();
    const updatedDivisions = await tx.objectStore<IDivision>(DIVISION_TABLE_NAME).getAll();
    const updatedDivisionDaysOfWeek = await tx.objectStore<IDivisionDayOfWeek>(DIVISION_DAYS_OF_WEEK_TABLE_NAME).getAll();
    const updatedEmployees = await tx.objectStore<IEmployee>(EMPLOYEE_TABLE_NAME).getAll();
    const updatedEmployeeRates = await tx.objectStore<IEmployeeRate>(EMPLOYEE_RATE_TABLE_NAME).getAll();
    const updatedFields = await tx.objectStore<IField>(FIELD_TABLE_NAME).getAll();
    const updatedJobTypes = await tx.objectStore<IJobType>(JOB_TYPE_TABLE_NAME).getAll();
    const updatedMinimumWageHistory = await tx.objectStore<IMinimumWageHistory>(MINIMUM_WAGE_HISTORY_TABLE_NAME).getAll();
    const updatedNews = await tx.objectStore<INews>(NEWS_TABLE_NAME).getAll();
    const updatedProductionUnits = await tx.objectStore<IProductionUnit>(PRODUCTION_UNIT_TABLE_NAME).getAll();
    const updatedProjects = await tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();
    const updatedPublicHolidays = await tx.objectStore<IPublicHoliday>(PUBLIC_HOLIDAY_TABLE_NAME).getAll();
    const updatedRootStocks = await tx.objectStore<IRootStock>(ROOT_STOCK_TABLE_NAME).getAll();
    const updatedScanTypes = await tx.objectStore<IScanType>(SCAN_TYPE_TABLE_NAME).getAll();
    const updatedSubdivisions = await tx.objectStore<ISubdivision>(SUBDIVISION_TABLE_NAME).getAll();
    const updatedTariffCalculations = await tx.objectStore<ITariffCalculation>(TARIFF_CALCULATION_TABLE_NAME).getAll();
    const updatedTariffTypes = await tx.objectStore<ITariffType>(TARIFF_TYPE_TABLE_NAME).getAll();
    const updatedVarieties = await tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();
    const updatedNorms = await tx.objectStore<INorm>(NORM_TABLE_NAME).getAll();
    const updatedPayRuns = await tx.objectStore<IPayRun>(PAY_RUN_TABLE_NAME).getAll();
    const updatedActivityRates = await tx.objectStore<IActivityRate>(ACTIVITY_RATE_TABLE_NAME).getAll();
    const updatedCompanyReferences = await tx.objectStore<ICompanyReference>(COMPANY_REFERENCE_TABLE_NAME).getAll();
    const updatedBusinessRules = await tx.objectStore<IBusinessRule>(BUSINESS_RULE_TABLE_NAME).getAll();
    const updatedGlCodes = await tx.objectStore<IGlCode>(GL_CODE_TABLE_NAME).getAll();
    const updatedCapitals = await tx.objectStore<ICapital>(CAPITAL_TABLE_NAME).getAll();
    const updatedDevices = await tx.objectStore<IDevice>(DEVICE_TABLE_NAME).getAll();
    const updatedDepartmentGroups = await tx.objectStore<IDepartmentGroup>(DEPARTMENT_GROUP_TABLE_NAME).getAll();
    // const updatedCropVarieties = await tx.objectStore<ICropVariety>(CROP_VARIETY_TABLE_NAME).getAll();
    const updatedEmployeeCards = await tx.objectStore<IEmployeeCard>(EMPLOYEE_CARD_TABLE_NAME).getAll();

    const updatedMasterData : IMasterDataSync = {
        activities: updatedActivities,
        activityTypes: updatedActivityTypes,
        blocks: updatedBlocks,
        clockingMethods: updatedClockingMethods,
        clockingSystems: updatedClockingSystems,
        employeeSetups: updatedEmployeeSetups,
        commodities: updatedCommodities,
        contactUs: updatedContactUs,
        countries: updatedCountries,
        countryPublicHolidayRules: updatedCountryPublicHolidayRules,
        crops: updatedCrops,
        departments: updatedDepartments,
        divisions: updatedDivisions,
        divisionDaysOfWeek: updatedDivisionDaysOfWeek,
        employees: updatedEmployees,
        employeeRates: updatedEmployeeRates,
        fields: updatedFields,
        jobTypes: updatedJobTypes,
        minimumWageHistories: updatedMinimumWageHistory,
        news: updatedNews,
        productionUnits: updatedProductionUnits,
        projects: updatedProjects,
        publicHolidays: updatedPublicHolidays,
        rootStocks: updatedRootStocks,
        scanTypes: updatedScanTypes,
        subdivisions: updatedSubdivisions,
        tariffCalculations: updatedTariffCalculations,
        tariffTypes: updatedTariffTypes,
        varieties: updatedVarieties,
        norms: updatedNorms,
        payRuns: updatedPayRuns,
        activityRates: updatedActivityRates,
        companyReferences: updatedCompanyReferences,
        businessRules: updatedBusinessRules,
        glCodes: updatedGlCodes,
        capitals: updatedCapitals,
        devices: updatedDevices,
        departmentGroups: updatedDepartmentGroups,
        // cropVarieties: updatedCropVarieties,
        employeeCards: updatedEmployeeCards,
    };

    await tx.complete;
    return updatedMasterData;
};


/*================================================================================================================
 *                                   Master Data IndexedDB Getters And Setters
 * ==============================================================================================================*/

export const setActivityMasterDataIndexedDB = async (activities : Array<IActivity>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(ACTIVITY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(ACTIVITY_TABLE_NAME);

        activities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getActivityMasterDataIndexedDB = async () : Promise<Array<IActivity> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IActivity>(ACTIVITY_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setActivityTypeMasterDataIndexedDB = async (activityTypes : Array<IActivityType>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(ACTIVITY_TYPE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);

        activityTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getActivityTypeMasterDataIndexedDB = async () : Promise<Array<IActivityType> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TYPE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IActivityType>(ACTIVITY_TYPE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setBlockMasterDataIndexedDB = async (blocks : Array<IBlock>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(BLOCK_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(BLOCK_TABLE_NAME);

        blocks.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getBlockMasterDataIndexedDB = async () : Promise<Array<IBlock> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BLOCK_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IBlock>(BLOCK_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setClockingMethodMasterDataIndexedDB = async (clockingMethods : Array<IClockingMethod>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(CLOCKING_METHOD_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(CLOCKING_METHOD_TABLE_NAME);

        clockingMethods.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getClockingMethodMasterDataIndexedDB = async () : Promise<Array<IClockingMethod> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CLOCKING_METHOD_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IClockingMethod>(CLOCKING_METHOD_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setClockingSystemMasterDataIndexedDB = async (clockingSystems : Array<IClockingSystem>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(CLOCKING_SYSTEM_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(CLOCKING_SYSTEM_TABLE_NAME);

        clockingSystems.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getClockingSystemMasterDataIndexedDB = async () : Promise<Array<IClockingSystem> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CLOCKING_SYSTEM_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IClockingSystem>(CLOCKING_SYSTEM_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setEmployeeSetupMasterDataIndexedDB = async (employeeSetups : Array<IEmployeeSetup>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(EMPLOYEE_SETUP_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(EMPLOYEE_SETUP_TABLE_NAME);

        employeeSetups.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getEmployeeSetupMasterDataIndexedDB = async () : Promise<Array<IEmployeeSetup> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(EMPLOYEE_SETUP_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IEmployeeSetup>(EMPLOYEE_SETUP_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setCommodityMasterDataIndexedDB = async (commodities : Array<ICommodity>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(COMMODITY_TABLE_NAME);

        commodities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getCommoditySystemMasterDataIndexedDB = async () : Promise<Array<ICommodity> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setContactUsMasterDataIndexedDB = async (contactUsData : Array<IContactUs>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(CONTACT_US_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(CONTACT_US_TABLE_NAME);

        contactUsData.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getContactUsMasterDataIndexedDB = async () : Promise<Array<IContactUs> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CONTACT_US_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IContactUs>(CONTACT_US_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setCountryMasterDataIndexedDB = async (countries : Array<ICountry>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(COUNTRY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(COUNTRY_TABLE_NAME);

        countries.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getCountryMasterDataIndexedDB = async () : Promise<Array<ICountry> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COUNTRY_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<ICountry>(COUNTRY_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setCountryPublicHolidayRuleMasterDataIndexedDB = async (countries : Array<ICountryPublicHolidayRule>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME);

        countries.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getCountryPublicHolidayRuleMasterDataIndexedDB = async () : Promise<Array<ICountryPublicHolidayRule> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<ICountryPublicHolidayRule>(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setCropMasterDataIndexedDB = async (crops : Array<ICrop>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(CROP_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(CROP_TABLE_NAME);

        crops.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getCropMasterDataIndexedDB = async () : Promise<Array<ICrop> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CROP_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<ICrop>(CROP_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setDepartmentMasterDataIndexedDB = async (departments : Array<IDepartment>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(DEPARTMENT_TABLE_NAME);

        departments.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getDepartmentMasterDataIndexedDB = async () : Promise<Array<IDepartment> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setDivisionMasterDataIndexedDB = async (departments : Array<IDivision>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(DIVISION_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(DIVISION_TABLE_NAME);

        departments.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getDivisionMasterDataIndexedDB = async () : Promise<Array<IDivision> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DIVISION_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IDivision>(DIVISION_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setDivisionDaysOfWeekMasterDataIndexedDB = async (departments : Array<IDivisionDayOfWeek>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(DIVISION_DAYS_OF_WEEK_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(DIVISION_DAYS_OF_WEEK_TABLE_NAME);

        departments.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getDivisionDaysOfWeekMasterDataIndexedDB = async () : Promise<Array<IDivisionDayOfWeek> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DIVISION_DAYS_OF_WEEK_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IDivisionDayOfWeek>(DIVISION_DAYS_OF_WEEK_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setEmployeeMasterDataIndexedDB = async (employees : Array<IEmployee>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(EMPLOYEE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(EMPLOYEE_TABLE_NAME);

        employees.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getEmployeeMasterDataIndexedDB = async () : Promise<Array<IEmployee> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(EMPLOYEE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IEmployee>(EMPLOYEE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setEmployeeRateMasterDataIndexedDB = async (employeeRateHistories : Array<IEmployeeRate>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(EMPLOYEE_RATE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(EMPLOYEE_RATE_TABLE_NAME);

        employeeRateHistories.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getEmployeeRateMasterDataIndexedDB = async () : Promise<Array<IEmployeeRate> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(EMPLOYEE_RATE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IEmployeeRate>(EMPLOYEE_RATE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setFieldMasterDataIndexedDB = async (fields : Array<IField>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(FIELD_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(FIELD_TABLE_NAME);

        fields.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getFieldMasterDataIndexedDB = async () : Promise<Array<IField> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FIELD_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IField>(FIELD_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setJobTypeMasterDataIndexedDB = async (jobTypes : Array<IJobType>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(JOB_TYPE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(JOB_TYPE_TABLE_NAME);

        jobTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getJobTypeMasterDataIndexedDB = async () : Promise<Array<IJobType> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(JOB_TYPE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IJobType>(JOB_TYPE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};
  
export const setMinimumWageHistoryMasterDataIndexedDB = async (minimumWageHistory : Array<IMinimumWageHistory>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MINIMUM_WAGE_HISTORY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(MINIMUM_WAGE_HISTORY_TABLE_NAME);

        minimumWageHistory.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getMinimumWageHistoryMasterDataIndexedDB = async () : Promise<Array<IMinimumWageHistory> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MINIMUM_WAGE_HISTORY_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IMinimumWageHistory>(MINIMUM_WAGE_HISTORY_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setNewsMasterDataIndexedDB = async (news : Array<INews>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NEWS_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(NEWS_TABLE_NAME);

        news.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getNewsMasterDataIndexedDB = async () : Promise<Array<INews> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NEWS_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<INews>(NEWS_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setProductionUnitMasterDataIndexedDB = async (productionUnits : Array<IProductionUnit>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
    
        const tx = db.transaction(PRODUCTION_UNIT_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    
        productionUnits.forEach((x) => {
            store.put(x, x.guid);
        });
    
        await tx.complete;
    }
};
  
export const getProductionUnitMasterDataIndexedDB = async () : Promise<Array<IProductionUnit> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
    
        const tx = db.transaction(PRODUCTION_UNIT_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IProductionUnit>(PRODUCTION_UNIT_TABLE_NAME)
            .getAll();
    
        await tx.complete;
        return result;
    }
};

export const setProjectMasterDataIndexedDB = async (projects : Array<IProject>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
    
        const tx = db.transaction(PROJECT_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(PROJECT_TABLE_NAME);
    
        projects.forEach((x) => {
            store.put(x, x.guid);
        });
    
        await tx.complete;
    }
};
  
export const getProjectMasterDataIndexedDB = async () : Promise<Array<IProject> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
    
        const tx = db.transaction(PROJECT_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IProject>(PROJECT_TABLE_NAME)
            .getAll();
    
        await tx.complete;
        return result;
    }
};

export const setPublicHolidayMasterDataIndexedDB = async (publicHolidays : Array<IPublicHoliday>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PUBLIC_HOLIDAY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(PUBLIC_HOLIDAY_TABLE_NAME);

        publicHolidays.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getPublicHolidayMasterDataIndexedDB = async () : Promise<Array<IPublicHoliday> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PUBLIC_HOLIDAY_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IPublicHoliday>(PUBLIC_HOLIDAY_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setRootStockMasterDataIndexedDB = async (rootStocks : Array<IRootStock>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ROOT_STOCK_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(ROOT_STOCK_TABLE_NAME);

        rootStocks.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getRootStockMasterDataIndexedDB = async () : Promise<Array<IRootStock> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ROOT_STOCK_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IRootStock>(ROOT_STOCK_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setScanTypeMasterDataIndexedDB = async (scanTypes : Array<IScanType>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SCAN_TYPE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(SCAN_TYPE_TABLE_NAME);

        scanTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getScanTypeMasterDataIndexedDB = async () : Promise<Array<IScanType> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SCAN_TYPE_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IScanType>(SCAN_TYPE_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setSubdivisionMasterDataIndexedDB = async (subdivisions : Array<ISubdivision>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SUBDIVISION_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(SUBDIVISION_TABLE_NAME);

        subdivisions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getSubdivisionMasterDataIndexedDB = async () : Promise<Array<ISubdivision> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SUBDIVISION_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<ISubdivision>(SUBDIVISION_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setTariffCalculationMasterDataIndexedDB = async (tariffCalculations : Array<ITariffCalculation>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TARIFF_CALCULATION_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(TARIFF_CALCULATION_TABLE_NAME);

        tariffCalculations.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getTariffCalculationMasterDataIndexedDB = async () : Promise<Array<ITariffCalculation> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TARIFF_CALCULATION_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<ITariffCalculation>(TARIFF_CALCULATION_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setTariffTypeMasterDataIndexedDB = async (tariffTypes : Array<ITariffType>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TARIFF_TYPE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(TARIFF_TYPE_TABLE_NAME);

        tariffTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getTariffTypeMasterDataIndexedDB = async () : Promise<Array<ITariffType> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TARIFF_TYPE_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<ITariffType>(TARIFF_TYPE_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};
  
export const setVarietyMasterDataIndexedDB = async (varieties : Array<IVariety>) : Promise<void> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(VARIETY_TABLE_NAME);

        varieties.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
  
export const getVarietyMasterDataIndexedDB = async () : Promise<Array<IVariety> | undefined> => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IVariety>(VARIETY_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setNormMasterDataIndexedDB = async (norms : Array<INorm>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NORM_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(NORM_TABLE_NAME);

        norms.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
      
export const getNormMasterDataIndexedDB = async () : Promise<Array<INorm> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NORM_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<INorm>(NORM_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setPayRunMasterDataIndexedDB = async (payRuns : Array<IPayRun>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PAY_RUN_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(PAY_RUN_TABLE_NAME);

        payRuns.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
      
export const getPayRunMasterDataIndexedDB = async () : Promise<Array<IPayRun> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PAY_RUN_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IPayRun>(PAY_RUN_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setActivityRateMasterDataIndexedDB = async (activityRates : Array<IActivityRate>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_RATE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(ACTIVITY_RATE_TABLE_NAME);

        activityRates.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getActivityRateMasterDataIndexedDB = async () : Promise<Array<IActivityRate> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_RATE_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IActivityRate>(ACTIVITY_RATE_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setCompanyReferenceMasterDataIndexedDB = async (companyRefs : Array<ICompanyReference>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMPANY_REFERENCE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(COMPANY_REFERENCE_TABLE_NAME);

        companyRefs.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
      
export const getCompanyReferenceMasterDataIndexedDB = async () : Promise<Array<ICompanyReference> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMPANY_REFERENCE_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<ICompanyReference>(COMPANY_REFERENCE_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setBusinessRuleMasterDataIndexedDB = async (businessRules : Array<IBusinessRule>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BUSINESS_RULE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(BUSINESS_RULE_TABLE_NAME);

        businessRules.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};
      
export const getBusinessRuleMasterDataIndexedDB = async () : Promise<Array<IBusinessRule> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BUSINESS_RULE_TABLE_NAME, 'readonly');
        const result = await tx
            .objectStore<IBusinessRule>(BUSINESS_RULE_TABLE_NAME)
            .getAll();

        await tx.complete;
        return result;
    }
};

export const setGlCodeMasterDataIndexedDB = async (glCodes : Array<IGlCode>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(GL_CODE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(GL_CODE_TABLE_NAME);

        glCodes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getGlCodeMasterDataIndexedDB = async () : Promise<Array<IGlCode> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(GL_CODE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IGlCode>(GL_CODE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setCapitalMasterDataIndexedDB = async (capitals : Array<ICapital>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(CAPITAL_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(CAPITAL_TABLE_NAME);

        capitals.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getCapitalMasterDataIndexedDB = async () : Promise<Array<ICapital> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CAPITAL_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<ICapital>(CAPITAL_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setDeviceMasterDataIndexedDB = async (devices : Array<IDevice>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(DEVICE_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(DEVICE_TABLE_NAME);

        devices.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getDeviceMasterDataIndexedDB = async () : Promise<Array<IDevice> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEVICE_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IDevice>(DEVICE_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

export const setDepartmentGroupMasterDataIndexedDB = async (departmentGroups : Array<IDepartmentGroup>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(DEPARTMENT_GROUP_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(DEPARTMENT_GROUP_TABLE_NAME);

        departmentGroups.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getDepartmentGroupMasterDataIndexedDB = async () : Promise<Array<IDepartmentGroup> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_GROUP_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IDepartmentGroup>(DEPARTMENT_GROUP_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};

// export const setCropVarietyMasterDataIndexedDB = async (cropVarieties : Array<ICropVariety>) : Promise<void> => {
//     // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
//     if (self.indexedDB) {
//         const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
//         const tx = db.transaction(CROP_VARIETY_TABLE_NAME, 'readwrite');
//         const store = tx.objectStore(CROP_VARIETY_TABLE_NAME);

//         cropVarieties.forEach((x) => {
//             store.put(x, x.guid);
//         });

//         await tx.complete;
//     }
// };

// export const getCropVarietyMasterDataIndexedDB = async () : Promise<Array<ICropVariety> | undefined> => {
//     // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
//     if (self.indexedDB) {
//         const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

//         const tx = db.transaction(CROP_VARIETY_TABLE_NAME, 'readonly');
//         const result = await tx.objectStore<ICropVariety>(CROP_VARIETY_TABLE_NAME).getAll();

//         await tx.complete;
//         return result;
//     }
// };

export const setEmployeeCardMasterDataIndexedDB = async (employeeCards : Array<IEmployeeCard>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(EMPLOYEE_CARD_TABLE_NAME, 'readwrite');
        const store = tx.objectStore(EMPLOYEE_CARD_TABLE_NAME);

        employeeCards.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
};

export const getEmployeeCardMasterDataIndexedDB = async () : Promise<Array<IEmployeeCard> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(EMPLOYEE_CARD_TABLE_NAME, 'readonly');
        const result = await tx.objectStore<IEmployeeCard>(EMPLOYEE_CARD_TABLE_NAME).getAll();

        await tx.complete;
        return result;
    }
};
