import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICropVariety } from '../../../@types/model/masterData/cropVariety/cropVariety';

export default class CropVarietyHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(departmentIds : Array<number> | null) : AxiosPromise<Array<ICropVariety>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/CropVariety/GetList`, {
            params: {
                departmentIds
            },
            paramsSerializer: {
                indexes: null,
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }
}