import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IProductionUnit } from '../../../@types/model/masterData/productionUnit/productionUnit';
import { IProductionUnitUpsert } from '../../../@types/model/masterData/productionUnit/productionUnitUpsert';

export default class ProductionUnitHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList() : AxiosPromise<Array<IProductionUnit>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ProductionUnit/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IProductionUnitUpsert, type : 'Add' | 'Edit') : AxiosPromise<IProductionUnit> => {
        return axios.post(`${API_URL}/v1/MasterData/ProductionUnit/${type}`, upsert);
    }

    public static delete = (productionUnitId : number) : AxiosPromise<IProductionUnit> => {
        return axios.delete(`${API_URL}/v1/MasterData/ProductionUnit/Delete`, {
            params: { productionUnitId },
        });
    }
}