import { createReducer } from '@reduxjs/toolkit';
import DataActions from './actions';
import { IBlock } from '../../@types/model/masterData/block/block';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { IField } from '../../@types/model/masterData/field/field';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../../@types/model/masterData/project/project';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IContactUs } from '../../@types/model/masterData/contactUs/contactUs';
import { INews } from '../../@types/model/masterData/news/news';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import { ICrop } from '../../@types/model/masterData/crop/crop';
import { ICropLine } from '../../@types/model/masterData/cropLine/cropLine';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import { IClockingMethod } from '../../@types/model/masterData/clockingMethod/clockingMethod';
import { IClockingSystem } from '../../@types/model/masterData/clockingSystem/clockingSystem';
import { IEmployeeSetup } from '../../@types/model/masterData/employeeSetup/employeeSetup';
import { IDivisionDayOfWeek } from '../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import { IEmployee } from '../../@types/model/masterData/employee/employee';
import { IEmployeeRate } from '../../@types/model/masterData/employeeRate/employeeRate';
import { IJobType } from '../../@types/model/masterData/jobType/jobType';
import { IMinimumWageHistory } from '../../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import { IPublicHoliday } from '../../@types/model/masterData/publicHoliday/publicHoliday';
import { IScanType } from '../../@types/model/masterData/scanType/scanType';
import { ITariffCalculation } from '../../@types/model/masterData/tariffCalculation/tariffCalculation';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import { INorm } from '../../@types/model/masterData/norm/norm';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { ICountryPublicHolidayRule } from '../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRule';
import { IPayRun } from '../../@types/model/masterData/payRun/payRun';
import { IActivityRate } from '../../@types/model/masterData/activityRate/activityRate';
import { ICompanyReference } from '../../@types/model/masterData/companyReference/companyReference';
import { IBusinessRule } from '../../@types/model/masterData/businessRule/businessRule';
import { IGlCode } from '../../@types/model/masterData/glCode/glCode';
import { ITariffType } from '../../@types/model/masterData/tariffType/tariffType';
import { ICapital } from '../../@types/model/masterData/capital/capital';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IDepartmentGroup } from '../../@types/model/masterData/departmentGroup/departmentGroup';
import { ICropVariety } from '../../@types/model/masterData/cropVariety/cropVariety';
import { IEmployeeCard } from '../../@types/model/masterData/employeeCard/employeeCard';

export interface IMasterDataState {
    // states
    masterDataSyncIsRunning : boolean;
    isLoading : boolean;

    // master data elements
    contactUsData : Array<IContactUs> | null;
    newsData : Array<INews> | null;
    countryData : Array<ICountry> | null;
    divisionData : Array<IDivision> | null;
    subdivisionData : Array<ISubdivision> | null;
    departmentData : Array<IDepartment> | null;
    productionUnitData : Array<IProductionUnit> | null;
    fieldData : Array<IField> | null;
    blockData : Array<IBlock> | null;
    commodityData : Array<ICommodity> | null;
    varietyData : Array<IVariety> | null;
    projectData : Array<IProject> | null;
    activityTypeData : Array<IActivityType> | null;
    activityData : Array<IActivity> | null;
    cropData : Array<ICrop> | null;
    cropLineData : Array<ICropLine> | null;
    rootStockData : Array<IRootStock> | null;
    clockingMethodData : Array<IClockingMethod> | null;
    clockingSystemData : Array<IClockingSystem> | null;
    employeeSetupData : Array<IEmployeeSetup> | null;
    divisionDayOfWeekData : Array<IDivisionDayOfWeek> | null;
    employeeData : Array<IEmployee> | null;
    employeeRateData : Array<IEmployeeRate> | null;
    jobTypeData : Array<IJobType> | null;
    minimumWageHistoryData : Array<IMinimumWageHistory> | null;
    publicHolidayData : Array<IPublicHoliday> | null;
    scanTypeData : Array<IScanType> | null;
    tariffTypeData : Array<ITariffType> | null;
    tariffCalculationData : Array<ITariffCalculation> | null;
    normData : Array<INorm> | null;
    countryPublicHolidayRuleData : Array<ICountryPublicHolidayRule> | null;
    payRunData : Array<IPayRun> | null;
    activityRateData : Array<IActivityRate> | null;
    companyReferenceData : Array<ICompanyReference> | null;
    businessRuleData : Array<IBusinessRule> | null;
    glCodeData : Array<IGlCode> | null;
    capitalData : Array<ICapital> | null;
    deviceData : Array<IDevice> | null;
    departmentGroupData : Array<IDepartmentGroup> | null;
    cropVarietyData : Array<ICropVariety> | null;
    employeeCardData : Array<IEmployeeCard> | null;

    //sync master Data
    syncedMasterData : Array<IMasterDataSync> | null;
    
    // user selected data
    selectedUserDivisionIds : Array<number> | null;
    selectedUserDepartmentIds : Array<number> | null;
    selectedUserDateFormat : string | null;
}

const initialState = {
    masterDataSyncIsRunning: false,
    isLoading: false,
    activityTypeData: null,
    activityData: null,
    blockData: null,
    clockingMethodData: null,
    clockingSystemData: null,
    employeeSetupData: null,
    commodityData: null,
    contactUsData: null,
    countryData: null,
    cropData: null,
    cropLineData: null,
    departmentData: null,
    divisionData: null,
    divisionDayOfWeekData: null,
    employeeData: null,
    employeeRateData: null,
    fieldData: null,
    jobTypeData: null,
    minimumWageHistoryData: null,
    newsData: null,
    productionUnitData: null,
    projectData: null,
    publicHolidayData: null,
    rootStockData: null,
    scanTypeData: null,
    subdivisionData: null,
    tariffCalculationData: null,
    tariffTypeData: null,
    varietyData: null,
    normData: null,
    countryPublicHolidayRuleData: null,
    payRunData: null,
    activityRateData: null,
    companyReferenceData: null,
    businessRuleData: null,
    glCodeData: null,
    capitalData: null,
    deviceData: null,
    departmentGroupData: null,
    cropVarietyData: null,
    employeeCardData: null,

    syncedMasterData: null,

    selectedUserDivisionIds: null,
    selectedUserDepartmentIds: null,
    selectedUserDateFormat: null,
};

const dataReducer = createReducer<IMasterDataState>(initialState, (builder) => {
    builder.addCase(DataActions.setIsMasterDataSyncRunning, (state, action) => {
        state.masterDataSyncIsRunning = action.payload;
    });

    builder.addCase(DataActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });

    builder.addCase(DataActions.setContactUsData, (state, action) => {
        state.contactUsData = action.payload;
    });
    
    builder.addCase(DataActions.setNewsData, (state, action) => {
        state.newsData = action.payload;
    });

    builder.addCase(DataActions.setCountryData, (state, action) => {
        state.countryData = action.payload;
    });

    builder.addCase(DataActions.setDivisionData, (state, action) => {
        state.divisionData = action.payload;
    });
    
    builder.addCase(DataActions.setSubdivisionData, (state, action) => {
        state.subdivisionData = action.payload;
    });

    builder.addCase(DataActions.setDepartmentData, (state, action) => {
        state.departmentData = action.payload;
    });

    builder.addCase(DataActions.setProductionUnitData, (state, action) => {
        state.productionUnitData = action.payload;
    });

    builder.addCase(DataActions.setFieldData, (state, action) => {
        state.fieldData = action.payload;
    });

    builder.addCase(DataActions.setBlockData, (state, action) => {
        state.blockData = action.payload;
    });

    builder.addCase(DataActions.setCommodityData, (state, action) => {
        state.commodityData = action.payload;
    });

    builder.addCase(DataActions.setVarietyData, (state, action) => {
        state.varietyData = action.payload;
    });

    builder.addCase(DataActions.setProjectData, (state, action) => {
        state.projectData = action.payload;
    });

    builder.addCase(DataActions.setActivityTypeData, (state, action) => {
        state.activityTypeData = action.payload;
    });

    builder.addCase(DataActions.setActivityData, (state, action) => {
        state.activityData = action.payload;
    });

    builder.addCase(DataActions.setCropData, (state, action) => {
        state.cropData = action.payload;
    });

    builder.addCase(DataActions.setCropLineData, (state, action) => {
        state.cropLineData = action.payload;
    });

    builder.addCase(DataActions.setRootStockData, (state, action) => {
        state.rootStockData = action.payload;
    });

    builder.addCase(DataActions.setClockingMethodData, (state, action) => {
        state.clockingMethodData = action.payload;
    });

    builder.addCase(DataActions.setClockingSystemData, (state, action) => {
        state.clockingSystemData = action.payload;
    });

    builder.addCase(DataActions.setEmployeeSetupData, (state, action) => {
        state.employeeSetupData = action.payload;
    });
    
    builder.addCase(DataActions.setDivisionDayOfWeekData, (state, action) => {
        state.divisionDayOfWeekData = action.payload;
    });

    builder.addCase(DataActions.setEmployeeData, (state, action) => {
        state.employeeData = action.payload;
    });

    builder.addCase(DataActions.setEmployeeRateData, (state, action) => {
        state.employeeRateData = action.payload;
    });

    builder.addCase(DataActions.setJobTypeData, (state, action) => {
        state.jobTypeData = action.payload;
    });

    builder.addCase(DataActions.setMinimumWageHistoryData, (state, action) => {
        state.minimumWageHistoryData = action.payload;
    });

    builder.addCase(DataActions.setPublicHolidayData, (state, action) => {
        state.publicHolidayData = action.payload;
    });

    builder.addCase(DataActions.setScanTypeData, (state, action) => {
        state.scanTypeData = action.payload;
    });

    builder.addCase(DataActions.setTariffTypeData, (state, action) => {
        state.tariffTypeData = action.payload;
    });

    builder.addCase(DataActions.setTariffCalculationData, (state, action) => {
        state.tariffCalculationData = action.payload;
    });

    builder.addCase(DataActions.setNormData, (state, action) => {
        state.normData = action.payload;
    });

    builder.addCase(DataActions.setCountryPublicHolidayRuleData, (state, action) => {
        state.countryPublicHolidayRuleData = action.payload;
    });

    builder.addCase(DataActions.setPayRunData, (state, action) => {
        state.payRunData = action.payload;
    });

    builder.addCase(DataActions.setActivityRateData, (state, action) => {
        state.activityRateData = action.payload;
    });

    builder.addCase(DataActions.setCompanyReferenceData, (state, action) => {
        state.companyReferenceData = action.payload;
    });

    builder.addCase(DataActions.setBusinessRuleData, (state, action) => {
        state.businessRuleData = action.payload;
    });

    builder.addCase(DataActions.setGlCodeData, (state, action) => {
        state.glCodeData = action.payload;
    });

    builder.addCase(DataActions.setCapitalData, (state, action) => {
        state.capitalData = action.payload;
    });

    builder.addCase(DataActions.setDeviceData, (state, action) => {
        state.deviceData = action.payload;
    });

    builder.addCase(DataActions.setDepartmentGroupData, (state, action) => {
        state.departmentGroupData = action.payload;
    });

    builder.addCase(DataActions.setCropVarietyData, (state, action) => {
        state.cropVarietyData = action.payload;
    });

    builder.addCase(DataActions.setEmployeeCardData, (state, action) => {
        state.employeeCardData = action.payload;
    });

    builder.addCase(DataActions.setSyncedMasterData, (state, action) => {
        state.activityData = action.payload.activities;
        state.activityTypeData = action.payload.activityTypes;
        state.blockData = action.payload.blocks;
        state.clockingMethodData = action.payload.clockingMethods;
        state.clockingSystemData = action.payload.clockingSystems;
        state.employeeSetupData = action.payload.employeeSetups;
        state.commodityData = action.payload.commodities;
        state.contactUsData = action.payload.contactUs;
        state.countryData = action.payload.countries;
        state.cropData = action.payload.crops;
        state.departmentData = action.payload.departments;
        state.divisionData = action.payload.divisions;
        state.divisionDayOfWeekData = action.payload.divisionDaysOfWeek;
        state.employeeData = action.payload.employees;
        state.employeeRateData = action.payload.employeeRates;
        state.fieldData = action.payload.fields;
        state.jobTypeData = action.payload.jobTypes;
        state.minimumWageHistoryData = action.payload.minimumWageHistories;
        state.newsData = action.payload.news;
        state.productionUnitData = action.payload.productionUnits;
        state.projectData = action.payload.projects;
        state.publicHolidayData = action.payload.publicHolidays;
        state.rootStockData = action.payload.rootStocks;
        state.scanTypeData = action.payload.scanTypes;
        state.subdivisionData = action.payload.subdivisions;
        state.tariffCalculationData = action.payload.tariffCalculations;
        state.tariffTypeData = action.payload.tariffTypes;
        state.varietyData = action.payload.varieties;
        state.normData = action.payload.norms;
        state.countryPublicHolidayRuleData = action.payload.countryPublicHolidayRules;
        state.payRunData = action.payload.payRuns;
        state.activityRateData = action.payload.activityRates;
        state.companyReferenceData = action.payload.companyReferences;
        state.businessRuleData = action.payload.businessRules;
        state.glCodeData = action.payload.glCodes;
        state.capitalData = action.payload.capitals;
        state.deviceData = action.payload.devices;
        state.departmentGroupData = action.payload.departmentGroups;
        // state.cropVarietyData = action.payload.cropVarieties;
        state.employeeCardData = action.payload.employeeCards;
    });

    builder.addCase(DataActions.setSelectedUserDivisionIds, (state, action) => {
        state.selectedUserDivisionIds = action.payload;
    });

    builder.addCase(DataActions.setSelectedUserDepartmentIds, (state, action) => {
        state.selectedUserDepartmentIds = action.payload;
    });

    builder.addCase(DataActions.setSelectedUserDateFormat, (state, action) => {
        state.selectedUserDateFormat = action.payload;
    });

    builder.addCase(DataActions.reset, () => {
        return initialState;
    });
});

export default dataReducer;
