import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IActivityUpsert } from '../../../@types/model/masterData/activity/activityUpsert';
import { IActivity } from '../../../@types/model/masterData/activity/activity';

export default class ActivityHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList() : AxiosPromise<Array<IActivity>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Activity/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IActivityUpsert, type : 'Add' | 'Edit') : AxiosPromise<IActivity> => {
        return axios.post(`${API_URL}/v1/MasterData/Activity/${type}`, upsert);
    }

    public static delete = (activityId : number) : AxiosPromise<IActivity> => {
        return axios.delete(`${API_URL}/v1/MasterData/Activity/Delete`, {
            params: { activityId },
        });
    }
}