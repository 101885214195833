import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IProject } from '../../../@types/model/masterData/project/project';
import { IProjectUpsert } from '../../../@types/model/masterData/project/projectUpsert';

export default class ProjectHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(departmentIds : Array<number> | null) : AxiosPromise<Array<IProject>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Project/GetList`, {
            params: [
                departmentIds
            ],
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IProjectUpsert, type : 'Add' | 'Edit') : AxiosPromise<IProject> => {
        return axios.post(`${API_URL}/v1/MasterData/Project/${type}`, upsert);
    };

    public static delete = (projectId : number) : AxiosPromise<IProject> => {
        return axios.delete(`${API_URL}/v1/MasterData/Project/Delete`, {
            params: { projectId },
        });
    };
}