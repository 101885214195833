import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IContactUs } from '../../../@types/model/masterData/contactUs/contactUs';
import { IContactUsUpsert } from '../../../@types/model/masterData/contactUs/contactUsUpsert';

export default class ContactUsHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(onlyHomeView : boolean) : AxiosPromise<Array<IContactUs>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ContactUs/GetList`, {
            params: {
                onlyHomeView,
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IContactUsUpsert, type : 'Add' | 'Edit') : AxiosPromise<IContactUs> => {
        return axios.post(`${API_URL}/v1/MasterData/ContactUs/${type}`, upsert);
    }

    public static delete = (contactUsId : number) : AxiosPromise<IContactUs> => {
        return axios.delete(`${API_URL}/v1/MasterData/ContactUs/Delete`, {
            params: { contactUsId },
        });
    }
}
