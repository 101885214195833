import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IClockingSystem } from '../../../@types/model/masterData/clockingSystem/clockingSystem';
import { IClockingSystemUpsert } from '../../../@types/model/masterData/clockingSystem/clockingSystemUpsert';

export default class ClockingSystemHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(departmentIds : Array<number> | null) : AxiosPromise<Array<IClockingSystem>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ClockingSystem/GetList`, {
            params: {
                departmentIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IClockingSystemUpsert, type : 'Add' | 'Edit') : AxiosPromise<IClockingSystem> => {
        return axios.post(`${API_URL}/v1/MasterData/ClockingSystem/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<IClockingSystem> => {
        return axios.delete(`${API_URL}/v1/MasterData/ClockingSystem/Delete`, {
            params: { id },
        });
    }
}