import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IMinimumWageHistory } from '../../../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import { IMinimumWageHistoryUpsert } from '../../../@types/model/masterData/minimumWageHistory/minimumWageHistoryUpsert';

export default class MinimumWageHistoryHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList() : AxiosPromise<Array<IMinimumWageHistory>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/MinimumWageHistory/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IMinimumWageHistoryUpsert, type : 'Add' | 'Edit') : AxiosPromise<IMinimumWageHistory> => {
        return axios.post(`${API_URL}/v1/MasterData/MinimumWageHistory/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<IMinimumWageHistory> => {
        return axios.delete(`${API_URL}/v1/MasterData/MinimumWageHistory/Delete`, {
            params: { id },
        });
    }
}