import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IDepartmentGroup } from '../../../@types/model/masterData/departmentGroup/departmentGroup';
import { IDepartmentGroupUpsert } from '../../../@types/model/masterData/departmentGroup/departmentGroupUpsert';

export default class DepartmentGroupHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(departmentIds : Array<number>) : AxiosPromise<Array<IDepartmentGroup>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/DepartmentGroup/GetList`, {
            params: {
                departmentIds
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : IDepartmentGroupUpsert, type : 'Add' | 'Edit') : AxiosPromise<IDepartmentGroup> => {
        return axios.post(`${API_URL}/v1/MasterData/DepartmentGroup/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<IDepartmentGroup> => {
        return axios.delete(`${API_URL}/v1/MasterData/DepartmentGroup/Delete`, {
            params: { id },
        });
    }
}